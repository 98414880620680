<template>
  <v-container class="pa-0 ma-0 login-container" fluid>
    <!-- Snackbar para notificaciones -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      top
    >
      <span class="h3">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false"> Cerrar </v-btn>
      </template>
    </v-snackbar>

    <v-row no-gutters class="login-row">
      <!-- Right side - Login form -->
      <v-col
        cols="12"
        md="8"
        order="1"
        order-md="2"
        class="d-flex flex-column align-stretch right-background py-6"
      >
        <div class="flex-grow-1 d-flex align-center justify-center" width="100%">
          <v-card flat max-width="400" width="100%" class="pa-8 transparent">
            <v-card-title class="text-h4 font-weight-bold mb-6">
              <transition appear name="slide-fade">
                <span>Login</span>
              </transition>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent="login">
                <v-text-field
                  v-model="credential.store"
                  label="Tienda"
                  :rules="storeRules"
                  id="store"
                  outlined
                  required
                  type="number"
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="credential.user_email"
                  label="Usuario"
                  outlined
                  required
                  dense
                  type="email"
                ></v-text-field>
                <v-text-field
                  v-model="credential.user_password"
                  label="Clave"
                  type="password"
                  :rules="passwordRules"
                  outlined
                  required
                  dense
                ></v-text-field>

                <v-btn
                  :disabled="!valid || loading_status"
                  :loading="loading_status"
                  color="success"
                  type="submit"
                  class="mr-4"
                >
                  Ingresar
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>

      <!-- Left side - Patterned background with grouped and centered content -->
      <v-col cols="12" md="4" order="2" order-md="1" class="left-background">
        <v-sheet
          height="100%"
          width="100%"
          class="d-flex flex-column align-stretch right-background py-6"
          :style="{ background: `${$vuetify.theme.themes.light.primary}` }"
        >
          <div class="flex-grow-1 d-flex align-center justify-center" width="100%">
            <div class="text-center">
              <v-card
                flat
                max-width="400"
                width="100%"
                class="pa-8 transparent justify-center"
              >
                <transition appear name="fade-in"> </transition>
                <transition appear name="slide-fade">
                  <h1 class="text-h3 font-weight-bold mb-4 white--text">AI-Pos</h1>
                </transition>
                <transition appear name="fade-in">
                  <p class="text-h6 white--text">Punto de venta Inteligente.</p>
                </transition>
              </v-card>
            </div>
          </div>

          <!-- Footer -->
          <v-footer dark class="mt-auto pa-4 transparent">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn x-small text href="/terminos" class="mx-2">
                  Términos y Condiciones
                </v-btn>
                <v-btn x-small text href="/privacidad" class="mx-2">
                  Aviso de Privacidad
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center mt-2 caption">
                © {{ new Date().getFullYear() }} NGM Corp. Todos los derechos reservados.
              </v-col>
            </v-row>
          </v-footer>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createProfile from "../utils/createProfile.js";

export default {
  name: "Login",

  props: {
    nextUrl: {
      default: null,
    },
  },

  data() {
    return {
      errors: [],
      valid: false,
      loading_status: false,
      snackbar: {
        show: false,
        text: "",
        color: "error",
        timeout: 5000,
      },
      storeRules: [
        (v) => !!v || "Tienda es requerida",
        (v) =>
          (v && v.toString().length >= 9) || "La tienda debe tener al menos 9 dígitos",
        (v) => /^\d+$/.test(v) || "Solo se permiten números",
      ],
      emailRules: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
      ],
      passwordRules: [
        (v) => !!v || "Contraseña es requerida",
        (v) => (v && v.length >= 3) || "La contraseña debe tener al menos 3 caracteres",
      ],
      credential: {
        store: null,
        user_email: null,
        user_password: null,
      },
      profile: createProfile(),
    };
  },

  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    this.$nextTick(() => {
      const storeInput = document.getElementById("store");
      if (storeInput) {
        storeInput.focus();
      }
    });
  },

  methods: {
    validate() {
      return this.$refs.form ? this.$refs.form.validate() : false;
    },

    showNotification(message, color = "error") {
      this.snackbar.text = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    handleLoginError(message) {
      this.loading_status = false;

      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.credential = {
        store: null,
        user_email: null,
        user_password: null,
      };

      this.showNotification(message);

      this.$nextTick(() => {
        this.valid = false;
        const storeInput = document.getElementById("store");
        if (storeInput) {
          storeInput.focus();
        }
      });
    },

    validateLoginResponse(data) {
      // Validar data
      if (!data || typeof data !== "object") {
        return { error: true, message: "Respuesta del servidor inválida" };
      }

      // Validar estado y fecha de vencimiento
      if (!data.store?.dueDate || !data.store?.status) {
        return { error: true, message: "Información de cuenta incompleta" };
      }

      // Validar vencimiento y estado
      const validationResult = this.validateDueDate(
        data.store.dueDate,
        data.store.status
      );

      if (validationResult.error) {
        return validationResult;
      }

      // Validar profile
      if (!data.profile) {
        return { error: true, message: "Datos de perfil no encontrados" };
      }

      // Solo validar campos críticos del profile
      const criticalProfileFields = [
        "store_id",
        "user_email",
        "user_name",
        "user_access",
      ];

      const missingProfileFields = criticalProfileFields.filter(
        (field) => !data.profile[field]
      );
      if (missingProfileFields.length > 0) {
        return {
          error: true,
          message: `Datos de perfil incompletos: ${missingProfileFields.join(", ")}`,
        };
      }

      // Validar seller
      if (!data.seller) {
        return { error: true, message: "Datos del vendedor no encontrados" };
      }

      // Solo validar campos críticos del seller
      const criticalSellerFields = ["PartyIdentification", "store_code", "PartyName"];

      const missingSellerFields = criticalSellerFields.filter(
        (field) => !data.seller[field]
      );
      if (missingSellerFields.length > 0) {
        return {
          error: true,
          message: `Datos del vendedor incompletos: ${missingSellerFields.join(", ")}`,
        };
      }

      // Validar store
      if (!data.store) {
        return { error: true, message: "Datos de la tienda no encontrados" };
      }

      // Solo validar campos críticos de la tienda
      const criticalStoreFields = ["store_id", "store_name", "store_category"];

      const missingStoreFields = criticalStoreFields.filter(
        (field) => !data.store[field]
      );
      if (missingStoreFields.length > 0) {
        return {
          error: true,
          message: `Datos de la tienda incompletos: ${missingStoreFields.join(", ")}`,
        };
      }

      // Validar token
      if (!data.token) {
        return { error: true, message: "Token de sesión inválido" };
      }

      // Validar que settings sea un array (puede estar vacío)
      if (!Array.isArray(data.settings)) {
        return { error: true, message: "Configuraciones inválidas" };
      }

      return { error: false, message: "Validación exitosa" };
    },

    validateDueDate(dueDate, status) {
      if (status !== "Active") {
        return { error: true, message: "La cuenta no está activa" };
      }

      const today = new Date();
      const expirationDate = new Date(dueDate);

      // Si la fecha de vencimiento ya pasó
      if (today > expirationDate) {
        return {
          error: true,
          message:
            "Su cuenta ha vencido. Por favor, contacte al administrador para renovar su suscripción.",
        };
      }

      // Calcular días restantes
      const diffTime = expirationDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      console.log("DIAS", diffDays);

      // Si quedan 5 días o menos, pero la cuenta aún no ha vencido
      if (diffDays <= 5 && diffDays > 0) {
        return {
          warning: true,
          daysLeft: diffDays,
          message: `Su cuenta vencerá en ${diffDays} día${
            diffDays === 1 ? "" : "s"
          }. Por favor, renueve su suscripción.`,
        };
      }

      return { error: false, warning: false };
    },

    login(e) {
      e.preventDefault();

      if (!this.validate()) {
        this.showNotification("Por favor, complete todos los campos correctamente");
        return;
      }

      this.loading_status = true;

      try {
        var md5 = require("md5");
        this.credential.user_password = md5(this.credential.user_password);
        const self = this;

        webserver("login", this.credential, (data) => {
          try {
            if (!data) {
              self.handleLoginError("Error de conexión con el servidor");
              return;
            }

            if (data.error) {
              self.handleLoginError(data.message || "Error en las credenciales");
              return;
            }

            const validationResult = self.validateLoginResponse(data);
            if (validationResult.error) {
              self.handleLoginError(validationResult.message);
              return;
            }

            // Validar vencimiento
            const dueDateValidation = self.validateDueDate(
              data.store.dueDate,
              data.store.status
            );
            if (dueDateValidation.error) {
              self.handleLoginError(dueDateValidation.message);
              return;
            }

            const proceedWithLogin = () => {
              try {
                self.profile.name = data.profile.user_name;
                self.profile.email = data.profile.user_email;
                self.profile.token = data.profile.token;
                self.profile.picture = data.profile.user_photo || "";
                self.profile.socialName = "SW67";
                self.profile.user_access = data.profile.user_access;

                self.$store.dispatch("setProfile", self.profile);
                self.$store.dispatch("setSeller", data.seller);

                window.token = data.token;
                window.profile = data.profile;
                window.curvas = data.curvas || [];
                window.store = data.store;
                window.seller = data.seller;

                window.settings = data.settings.reduce((r, a) => {
                  r[a.tabla] = r[a.tabla] || [];
                  r[a.tabla].push(a);
                  return r;
                }, Object.create(null));

                self.$store.dispatch("setNavStatus", true);
                self.$store.dispatch("set_menu", true);

                self.showNotification("Inicio de sesión exitoso", "success");

                // Delay before redirect
                setTimeout(() => {
                  if (self.nextUrl) {
                    self.$router.push(self.nextUrl);
                  } else {
                    self.$router.push("/PLU");
                  }
                }, 1000);
              } catch (error) {
                console.error("Error procesando datos:", error);
                self.handleLoginError("Error procesando la respuesta del servidor");
              }
            };

            // Si hay advertencia, mostrar y esperar antes de continuar
            if (dueDateValidation.warning) {
              self.showNotification(dueDateValidation.message, "warning");
              setTimeout(proceedWithLogin, 5000);
            } else {
              proceedWithLogin();
            }
          } catch (error) {
            console.error("Error en proceso de login:", error);
            self.handleLoginError("Error en el proceso de inicio de sesión");
          }
        });
      } catch (error) {
        console.error("Error general:", error);
        this.handleLoginError("Error general en el proceso");
      }
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.login-container {
  height: calc(100vh);
  position: relative;
  overflow-y: auto;
}

.login-row {
  min-height: 100%;
}

.left-background {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 60px 60px;
}

.right-background {
  background-color: #f5f5f5;
}

.login-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}
.fade-in-enter {
  opacity: 0;
}

.fade-in-up-enter-active {
  transition: all 0.5s;
}
.fade-in-up-enter {
  transform: translateY(20px);
  opacity: 0;
}

.v-btn--floating {
  position: relative;
}

@media (max-width: 959px) {
  .login-container {
    height: calc(100vh);
    padding-bottom: 56px;
  }
}
</style>
